<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Description:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.description"
                  placeholder="Description"
                />
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="">Image / File:</label><br />
              <!-- <p class="image__hint"><i>Max 5 mb</i></p> -->
              <validation-provider name="image" rules="required">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="changeFile($event)"
                  accept=".jpeg, .png, .jpg, .pdf"
                />
                <small>Current file:</small><br />
                <img
                  crossorigin="anonymous"
                  v-if="
                    (file_url !== null && file_type == 'image') ||
                    file_type == 'jpeg' ||
                    file_type == 'png' ||
                    file_type == 'jpg'
                  "
                  :src="file_url"
                  class="media_file"
                />
                <div v-else-if="file_url !== null && file_type == 'pdf'" class="d-flex align-items-center mt-1">
                  <feather-icon
                    icon="FileIcon"
                    class="text-body"
                    size="18"
                  />
                  <a :href="file_url" download> Link download file </a>
                </div>
              </validation-provider>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="status"> Status: </label>
              <validation-provider name="status" rules="required">
                <v-select
                  id="status"
                  label="title"
                  v-model="formPayload.status"
                  :options="option"
                  placeholder="-- Pilih Status --"
                  :reduce="(option) => option.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BFormFile,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormFile,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        name: "",
        status: "",
      },
      validations: "",
      option: [
        { title: "Approve", value: "success" },
        { title: "Reject", value: "failed" },
      ],
      file_url: null,
      file_type: "",
      fileContent: "",
      // Must be an array reference!
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    loadData() {
      const params = this.$route.params.id;
      this.$http.get("/admin/withdrawal/" + params).then((response) => {
        this.formPayload = response.data.data;
        if (this.formPayload.proofOfPayment !== null) {
          this.file_url = this.formPayload.proofOfPayment;
          this.file_type = this.file_url.substr(
            this.file_url.lastIndexOf(".") + 1
          );
          console.log(this.file_type, "ini");
          this.fileContent = "file";
        }
      });
    },
    changeFile(event) {
      this.formPayload.proofOfPayment = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        var str = e.target.result;
        var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
        var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
        var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
        this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },
    saveItem() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to approve this Provider?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const params = this.$route.params.id;
          this.isLoading = true;
          const payload = new FormData();
          payload.append("description", this.formPayload.description);
          payload.append("status", this.formPayload.status);
          payload.append("proofOfPayment", this.formPayload.proofOfPayment);
          this.$http
            .put(`/admin/withdrawal/${params}`, payload)
            .then((response) => {
              this.isLoading = false;
              successNotification(
                this,
                "Success",
                "Withdrawal sukses diupdate!"
              );
              this.$router.push({ name: "withdrawal" });
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta) {
                this.validations = error.response.data.meta.message;
                errorNotification(this, "Error", this.validations.join(", "));
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
